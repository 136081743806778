export let courseRule ={
    title: [
      { required: true, message: "标题不能为空!", trigger: "blur" },
      { required: true, message: "标题不能为空!", trigger: "change" },
    ],
    mechanism_id: [
      { required: true, message: "请选择机构!", trigger: "change" },
    ],

    categories_child: [
      { required: true, message: "请选择类目!", trigger: "change" },
    ],
    appointment_type: [
      { required: true, message: "请选择排课类型!", trigger: "change" },
    ],
    card_type: [
      { required: true, message: "请选择卡类型!", trigger: "change" },
    ],
    settlement_cash: [
      { required: true, message: "请填写结算金额", trigger: "change" },
    ],
    settlement_course: [
      { required: true, message: "请填入天赋课数!", trigger: "blur" },
      { required: true, message: "请填入天赋课数!", trigger: "change" },
    ],
    course_num: [
      { required: true, message: "请填入正课数!", trigger: "blur" },
      { required: true, message: "请填入正课数!", trigger: "change" },
    ],
    face_url: [
      { required: true, message: "请上传封面!", trigger: "change" },
    ],
    connect_peoplenum: [
      { required: true, message: "请填入最大人数!", trigger: "change" },
      { required: true, message: "请填入最大人数!", trigger: "blur" },
    ],
    discount_amout: [
      { required: true, message: "请填写课程售价!", trigger: "change" },
      { required: true, message: "请填写课程售价!", trigger: "blur" },
    ],
    
    length_of_lesson: [
      { required: true, message: "请填入课时长!", trigger: "change" },
      { required: true, message: "请填入课时长!", trigger: "blur" },
    ],
    duration: [
      { required: true, message: "请填入有效期!", trigger: "change" },
      { required: true, message: "请填入有效期!", trigger: "blur" },
    ],
    apply_age: [
      { required: true, message: "请选择适用年龄!", trigger: "change" },
    ],
    school_age: [
      { required: true, message: "请选择适用适用人群!", trigger: "change" },
    ],
  }

